import camelCase from 'lodash/camelCase';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line node/no-extraneous-import
import {getCountryCallingCode} from 'react-phone-number-input/max';
// the above import comes as a dependency of @teladoc/pulse/ui/phone-input
import UserAPI from '@teladoc/fe-ccm/ui/user/user-api';
import APIUtils, {APIRegistry} from '@livongo/utilities/system/api';
import StorageUtils from '@livongo/utilities/system/storage';
import CommonUtils from '../common/utilities/common-utils';
import {
    MY_REFRESH_COOKIE,
    ACCESS_TOKEN_COOKIE,
    REFRESH_TOKEN_COOKIE,
    EDUCATION_TOKEN_COOKIE,
    ONBOARDING_INTRO_SEEN,
    CORE_PROGRAMS,
    MEMBER_ID,
} from '../config';
import {getStore} from '../store';
import programs, {PROGRAM_STATUSES} from '../programs';
import WeightAPI from '../weight/weight-api';
import BloodPressureAPI from '../blood-pressure/blood-pressure-api';
import MixpanelUtils from '../common/utilities/mix-panel';

function cleanPhoneNumber(number) {
    return number.replace(/[\s()-]+/gi, '');
}

// The backend doesn't handle a user submitting their phone number with the calling code currently. So, remove it!
function removeCallingCode(phoneNumber, callingCode) {
    const codeLength = callingCode.length;

    return phoneNumber.substring(0, codeLength) === callingCode
        ? phoneNumber.slice(codeLength)
        : phoneNumber;
}

const PHONE_TYPES = {
    MO: 'mobile',
    HO: 'home',
};

const UserUtils = {
    SSO_STATUS: {
        ESTABLISHED: 'ESTABLISHED',
        ACCOUNT_MERGE: 'ACCOUNT_MERGE',
        FAILURE: 'FAILURE',
    },

    normalizePhones(phones) {
        const phoneNumbers = {};

        phones.forEach(
            ({phoneType, countryCode = 'US', number, numberE164}) => {
                const callingCode = getCountryCallingCode(countryCode);

                phoneNumbers[PHONE_TYPES[phoneType]] = {
                    // international numbers come with their callingCode in place but we don't need it because we set it based on countryCode in the UI so remove it here
                    number: numberE164
                        ? numberE164.replace(`+${callingCode}`, '')
                        : number,
                    countryCode,
                };
            }
        );

        return phoneNumbers;
    },

    getNumberData({number, countryCode}) {
        const cleanNumber = cleanPhoneNumber(number);
        const callingCode = getCountryCallingCode(countryCode);

        return {
            number: removeCallingCode(cleanNumber, callingCode),
            numberE164: `+${callingCode}${removeCallingCode(
                cleanNumber,
                callingCode
            )}`,
            prefix: `+${callingCode}`,
        };
    },

    getDeviceByCategory({groups, categories}) {
        for (const {deviceGroupings} of groups) {
            for (const {readingCategory, current} of deviceGroupings) {
                if (includes(categories, readingCategory.toLowerCase())) {
                    return camelCase(current.bundleType);
                }
            }
        }
    },

    formatContactData({
        id,
        firstName,
        lastName,
        contactType,
        phoneType,
        email = null,
        phoneNumber = null,
    }) {
        return {
            firstName,
            lastName,
            contactType,
            email,
            phones: [
                {
                    number: phoneNumber ? cleanPhoneNumber(phoneNumber) : null,
                    phoneType: phoneType || 'MO',
                },
            ],
            ...(id && {id}),
        };
    },

    formatAlertSettingsData({
        settings: {low, high},
        contact: {id, email, phoneNumber, sugarLevels = {}},
    }) {
        const contactType = 'FA';
        const newDestination = [];
        const destinationsLow = low.destinations.filter(
            ({contactId}) => contactId !== id
        ); // Remove contactId from destination list if it already exists
        const destinationsHigh = high.destinations.filter(
            ({contactId}) => contactId !== id
        ); // Remove contactId from destination list if it already exists

        if (email) {
            newDestination.push({
                address: email,
                channel: 'EMAIL',
                contactId: id,
                contactType,
            });
        }

        if (phoneNumber) {
            newDestination.push({
                address: phoneNumber,
                channel: 'SMS',
                contactId: id,
                contactType,
            });
        }

        return {
            low: {
                ...low,
                destinations: [
                    ...destinationsLow,
                    ...(sugarLevels.low ? newDestination : []),
                ],
            },
            high: {
                ...high,
                destinations: [
                    ...destinationsHigh,
                    ...(sugarLevels.high ? newDestination : []),
                ],
            },
        };
    },

    async checkAccountDependentCalls({data}) {
        const {
            groups: {
                organization: {code: companyId},
            },
        } = data;

        const {
            data: {
                client: {
                    attributes: {addressTypes, isD2C, phoneNumberRegions},
                },
            },
        } = await APIUtils.get(`v1/promos/${companyId}`);

        return {
            companyId,
            addressTypes,
            isDirectToConsumer: isD2C,
            isPOBoxAllowed: addressTypes.includes('POB'),
            isInternational: phoneNumberRegions.includes('INTERNATIONAL'),
        };
    },

    async checkProgramDependentCalls({data, types, statuses}) {
        const userPrograms = data.getProgramsList().map(item => {
            const program = Object.keys(types).find(
                key => types[key] === item.getId()
            );
            const status = Object.keys(statuses).find(
                key => statuses[key] === item.getStatus()
            );

            return {
                program,
                status,
                ...find(programs, ['dbSlug', program]),
            };
        });
        const activePrograms = userPrograms?.reduce(
            (programsList, {id, status}) => {
                if (status === 'ACTIVE') {
                    programsList[id] = true;
                }

                return programsList;
            },
            {}
        );
        const {
            weight: hasWeight,
            advancedWeight: hasAdvancedWeight,
            comprehensiveWeight: hasComprehensiveWeight,
            prediabetes: hasPrediabetes,
            advancedPrediabetes: hasAdvancedPrediabetes,
            comprehensivePrediabetes: hasComprehensivePrediabetes,
            heartFailure: hasHeartFailure,
            bloodPressure: hasBloodPressure,
            chronicKidneyDisease: hasKidneyDisease,
        } = activePrograms;
        const hasWeightManagement = Boolean(
            hasWeight ||
                hasAdvancedWeight ||
                hasComprehensiveWeight ||
                hasPrediabetes ||
                hasAdvancedPrediabetes ||
                hasComprehensivePrediabetes
        );
        const hasFiveDayCheck = Boolean(hasBloodPressure || hasKidneyDisease);
        let hasSetPersonalGoalWeight = false;

        if (hasWeightManagement) {
            const {selfEnteredTarget} = (await WeightAPI.getTarget()) || {};

            hasSetPersonalGoalWeight = selfEnteredTarget;
        }

        return {
            activePrograms,
            hasFiveDayCheck,
            hasWeightManagement,
            programs: userPrograms,
            hasSetPersonalGoalWeight,
            diagnoses: data?.getDiagnosesList?.(),
            hasSafeContent: Boolean(hasKidneyDisease || hasHeartFailure),
            ...((hasBloodPressure || hasKidneyDisease || hasHeartFailure) && {
                bloodPressureStats: await BloodPressureAPI.getStatistics(),
            }),
            ...(!hasWeightManagement && {
                weightReadings: await WeightAPI.getReadings({
                    start: new Date(0),
                }),
            }),
        };
    },

    logout() {
        MixpanelUtils.reset();
        StorageUtils.remove({
            key: ACCESS_TOKEN_COOKIE,
            type: 'cookie',
            useNative: true,
        });
        StorageUtils.remove({
            key: REFRESH_TOKEN_COOKIE,
            type: 'cookie',
            useNative: true,
        });
        StorageUtils.remove({
            key: MY_REFRESH_COOKIE,
            type: 'cookie',
            useNative: true,
        });
        // Temporary solution to provide support for iOS once they have this feature in place (around SEPTEMBER) we will remove this
        StorageUtils.remove({
            key: EDUCATION_TOKEN_COOKIE,
            type: 'cookie',
            useNative: true,
        });
        StorageUtils.remove({
            key: ONBOARDING_INTRO_SEEN,
            type: 'session',
        });
    },

    setRegistrationCookie() {
        const common = {type: 'cookie', useNative: true};

        // This localStorage key avoids Registration redirecting back to Member Portal
        StorageUtils.set({key: 'lvNewUserIncomplete', value: true, ...common});
        StorageUtils.remove({key: 'lvNewUser', ...common});
        MixpanelUtils.track({event: 'login.signupnow.clicked'});
    },

    willRender(accesses) {
        const store = getStore();
        const {
            user: {programs: userPrograms},
        } = store.getState();

        return (
            isEmpty(accesses) ||
            userPrograms
                ?.filter(({status}) => status === PROGRAM_STATUSES.ACTIVE)
                ?.some(({slug}) => accesses.includes(slug))
        );
    },

    hasOtherReports() {
        const store = getStore();
        const {user} = store.getState();
        const {
            activePrograms: {bloodPressure: hasBloodPressure},
        } = user;

        // Blood pressure is the only program that has another report other than the Multi Condition Health Summary
        return hasBloodPressure && user.hasPersonalizedBPReport;
    },

    updateHeaders(options) {
        const apiDefaults = APIRegistry.get('default').instance.defaults;

        apiDefaults.headers = {
            ...apiDefaults.headers,
            ...options,
        };
    },

    appendParamToUrl({url, param}) {
        /* eslint-disable node/prefer-global/url */
        // Create a URL object for easier manipulation
        const urlObj = new URL(url);

        // Special case for 'mystrength' and 'es-US'
        if (url.includes('mystrength') && param.language === 'es-US') {
            param.language = 'es-MX'; // Replace 'es-US' with 'es-MX'
        }

        // Loop through the keys in the param object and add them to the URL
        Object.keys(param).forEach(key => {
            const value = param[key];

            if (value) {
                // Append the key-value pair as a query parameter
                urlObj.searchParams.append(key, value);
            }
        });

        // Return the updated URL with all parameters appended
        return urlObj.toString();
        /* eslint-enable node/prefer-global/url */
    },

    checkForPOBox(str) {
        return str.match(/P[.\s]*O[.\s]+Box/gim);
    },

    async generateAccessCode() {
        const response = await UserAPI.getAccessCode();
        const accessCode = response?.code || '';

        return accessCode;
    },

    userHasCorePrograms(programlist) {
        let hasCore = false;

        programlist?.forEach(program => {
            switch (program) {
                case CORE_PROGRAMS.DM:
                case CORE_PROGRAMS.HTN:
                case CORE_PROGRAMS.DPP:
                case CORE_PROGRAMS.WM:
                    hasCore = true;
                    break;
            }
        });

        return hasCore;
    },
    // returns an custom_data object that can be use in Apptentive custom filter
    getApptentiveCustomAttribute({
        userPrograms,
        userActivePrograms,
        clientCode,
        userId,
        isOneApp,
    }) {
        try {
            const hasCorePrograms =
                this.userHasCorePrograms(userActivePrograms);
            const isCcmEligible = userActivePrograms?.length > 0;
            const memberId = CommonUtils.getCookie({key: MEMBER_ID});

            const customData = {
                // eslint-disable-next-line camelcase
                custom_data: {
                    'Core CCM Program Enrolled': Boolean(
                        hasCorePrograms ?? false
                    ),
                    'CCM Eligible': Boolean(isCcmEligible),
                    'Client Code': String(clientCode),
                    'User ID': String(userId ?? ''),
                    'Teladoc Member ID': String(memberId ?? ''),
                    'OneApp Member': Boolean(isOneApp ?? false),
                },
            };

            userPrograms.forEach(program => {
                customData.custom_data[program.program] = program.status;
            });

            return customData;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('[ERROR: getApptentiveCustomAttribute]', e);
        }

        return {};
    },
};

export default UserUtils;
